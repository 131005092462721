<template>
    <flexbox v-bind:variant="variant">
        <payment-method-icon name="mastercard"/>
        <payment-method-icon name="maestro"/>
        <payment-method-icon name="visa"/>
        <payment-method-icon name="mir"/>
        <payment-method-icon name="sbp"/>
        <payment-method-icon name="yoomoney"/>
    </flexbox>
</template>

<script>
import Flexbox from "@/components/Flexbox";
import PaymentMethodIcon from "@/components/PaymentMethod";

export default {
    name: "PaymentMethods",
    components: { PaymentMethodIcon, Flexbox },
    props: {
        variant: {
            type: String,
            default: 'around',
        }
    }
}
</script>

<style scoped lang="sass">
.qiwi-logo
    filter: grayscale(100%) brightness(2000%)
</style>
