<template>
    <div>
        <h1>Платёжная система для избранных</h1>
        <p>Доступна только лучшему хостингу Minecraft серверов в России</p>
    </div>

    <div>
        <h3>Работаем с</h3>
        <payment-providers size="48"/>
    </div>

    <div>
        <h3>Способы оплаты</h3>
        <payment-methods/>
    </div>
</template>

<script>
import PaymentProviders from "@/components/PaymentProviders";
import PaymentMethods from "@/components/PaymentMethods";

export default {
    name: "Home",
    components: { PaymentMethods, PaymentProviders }
}
</script>

<style scoped lang="sass">
@import "../style/variables.sass"

h1
    margin: 0

    @media screen and (min-width: $small-screen-size)
        font-size: 3em

h3
    $height: 2px

    text-transform: uppercase
    font-weight: bold
    margin: 32px auto
    position: relative

    @media screen and (min-width: $small-screen-size)
        width: 50%

        &:before, &:after
            content: ''
            position: absolute
            top: calc(50% - #{ $height })
            width: 30%
            background-color: $main-text-color
            height: $height

        &:before
            left: 0

        &:after
            right: 0

p
    @media screen and (min-width: $small-screen-size)
        font-size: 1.5em

div
    text-align: center
    margin: 64px auto
</style>
