<template>
    <flexbox>
        <a href="https://www.tinkoff.ru/kassa/" target="_blank">
            <img v-bind:height="size / 2" src="https://acdn.tinkoff.ru/static/documents/da250c81-b609-42ee-accf-e059c27abbf4.svg" class="tinkoff-logo" alt="">
        </a>
    </flexbox>
</template>

<script>
import Flexbox from "@/components/Flexbox";
export default {
    name: "PaymentProviders",
    components: { Flexbox },
    props: {
        size: {
            type: Number,
            default: 48,
        }
    }
}
</script>

<style scoped lang="sass">
.qiwi-logo, .tinkoff-logo
    filter: contrast(0) brightness(2000%)
</style>
