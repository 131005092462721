<template>
    <div v-bind:class="variant">
        <slot/>
    </div>
</template>

<script>
export default {
    name: "Flexbox",
    props: {
        variant: {
            type: String,
            default: 'around',
        }
    }
}
</script>

<style scoped lang="sass">
div
    display: flex
    gap: 16px
    flex-wrap: wrap
    max-width: 768px

    &.around
        justify-content: space-around

    &.start
        justify-content: flex-start
</style>
