<template>
    <main>
        <div class="content">
            <router-view/>
        </div>
        <footer>
            <small>Superhub Pay. &copy; <a href="https://superhub.host">Hosting Superhub</a>, 2021 - {{ new Date().getFullYear() }}</small>
        </footer>
    </main>
</template>

<style lang="sass">
@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap')
@import 'style/variables.sass'

$footer-height: 48px

html, body
    font-family: 'Nunito', sans-serif
    background-color: $main-background-color
    color: $main-text-color
    padding: 0
    margin: 0
    font-size: 18px

main
    display: flex
    flex-flow: column
    align-content: center
    justify-content: space-between
    min-height: 100vh

    .content
        margin: auto
        max-width: 1024px

        @media screen and (max-width: $small-screen-size)
            margin: 16px

footer
    height: $footer-height
    background-color: $footer-background-color
    text-align: center
    position: relative
    color: $gray-text-color
    display: flex
    align-items: center

    small
        display: block
        margin: auto

    a
        color: inherit

*::-webkit-scrollbar
    width: 4px

*::-webkit-scrollbar-thumb
    background-color: $footer-background-color
</style>
