<template>
    <img v-bind:src="link" v-bind:alt="name" width="48" v-bind:class="variant">
</template>

<script>
export default {
    name: "PaymentMethodIcon",
    props: {
        name: {
            type: String,
            required: true,
        },
        variant: {
            type: String,
            default: 'logo',
        }
    },
    computed: {
        link: function () {
            return `https://cdn.superhub.host/images/payment-systems/${ this.fileName }`
        },
        fileName: function () {
            if (this.variant === 'logo') return `logo-${ this.name }.png`
            switch (this.name) {
                case 'card':
                case 'mobile':
                case 'ethereum':
                case 'bitcoin':
                    return `method-${ this.name }-white.png`
                case 'tinkoff':
                    return `method-card-white.png`
                case 'yoomoney':
                    return `method-yandex-money.svg`
                default:
                    return `method-${ this.name }.svg`
            }
        }
    }
}
</script>
